import {
  Component,
  OnInit,
  ComponentRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import * as moment from "moment";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UserDetailComponent } from "../../shared/user-detail/user-detail.component";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";

@Component({
  selector: "app-pardna-details",
  templateUrl: "./pardna-details.component.html",
  styleUrls: ["./pardna-details.component.scss"],
})
export class PardnaDetailsComponent implements OnInit {
  _id: any;
  pardnaDetails;

  popoversMap = new Map<any, ComponentRef<UserDetailComponent>>();
  popoverFactory = this.resolver.resolveComponentFactory(UserDetailComponent);
  modalRef: BsModalRef;

  constructor(
    private router: Router,
    private alertnotification: AlertNotification,
    private apiServiceService: ApiServiceService,
    private route: ActivatedRoute,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private modalService: BsModalService,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this._id = params.id;
    });

    this.getPardnaDetail(this._id);
  }

  getPardnaDetail(id) {
    this.firebaseservice
      .getPardnaByRef(id)
      .then(
        async function (result) {
          var data = {};
          data = result.data();
          data["id"] = result.id;
          // console.log(data)
          this.pardnaDetails = data;
        
          // this.pardnaDetails = Object.keys(data).map((x) => {
          var sortmembers = [];
          if (!data["paid"]) {
            var newSort = Object.keys(data["members"]);
            for (var i = 0; i < newSort.length; i++) {
              const userDetail = await this.getUserDetail(newSort[i]);
              if (data["members"][newSort[i]] == 1) {
                sortmembers[data["members"][newSort[i]]] = {
                  id: newSort[i],
                  paid: true,
                  userdetail: userDetail,
                };
               
              } else {
                sortmembers[data["members"][newSort[i]]] = {
                  id: newSort[i],
                  paid: false,
                  userdetail: userDetail,
                };
              }
            }
          } else {
            var paidMember = data["paid"] ? Object.keys(data["paid"]) : [];
            const newSort: any[] = data["members"]
              ? Object.keys(data["members"])
              : [];
            var boolean = false;
            for (var i = 0; i < newSort.length; i++) {
              sortmembers[data["members"][newSort[i]]] = { id: newSort[i] };
            }
            sortmembers = sortmembers.filter((x) => x !== "empty");
            sortmembers = sortmembers.map((x) => {
              // console.log(paidMember.indexOf(x.id) , x.id)
              var newObject = {};
              if (paidMember.indexOf(x.id) !== -1) {
                newObject = {
                  id: x.id,
                  paid: "paid",
                };
              } else {
                newObject = {
                  id: x.id,
                  paid: boolean == false ? true : false,
                };
                boolean = true;
              }
              // console.log(newObject);
              return newObject;
            });

            for (var i = 0; i < sortmembers.length; i++) {
              const userDetail = await this.getUserDetail(sortmembers[i].id);
              sortmembers[i]["userdetail"] = userDetail;
            }
          }
          sortmembers = sortmembers.filter((x) => x !== "empty");
          // console.log(data['members']);
          // console.log(sortmembers);
          this.pardnaDetails["clientMemberArray"] = sortmembers;
          console.log(this.pardnaDetails);
          // });
        
          this.loaderService.hide();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }

  getUserDetail(id) {
    var userDetail;
    var records1 = [];
    return this.firebaseservice
      .getUserById(id)
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records1.push(data);
          });
          return records1[0];
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }
  setMemberPaidManual(memberId, pardnaId){
    console.log(memberId, pardnaId);
    Swal.fire({
      title: "Manually mark this user as paid??",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paid",
    }).then((result) => {
      if (result.value) {
        this.firebaseservice
            .updatePardnaPaymentStatus(pardnaId, memberId)
            .then(() => {
              this.loaderService.hide();
              this.pardnaDetails.payment_pending = this.pardnaDetails.payment_pending.filter(id => id !== memberId); 
              this.pardnaDetails.payments = { ...this.pardnaDetails.payments, [memberId]: "manual" };
              Swal.fire("Manually marked paid  successfully").then(() => {
                // this.getPage(this.p);
                // this.getCount();
                
              });
              console.log(this.pardnaDetails);
            })
            .catch(() => {
              this.loaderService.hide();
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Unable to update payment!",
              });
            });
        }else{
          return;
        }
    });
    
  }

  setMemberPaid(memberId, user) {
    Swal.fire({
      title: "Do you want to mark this user as paid?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paid",
    }).then((result) => {
      console.log(result,"result123");
      if (result.value) {
        var payment_pending = user.payment_pending ? user.payment_pending : [];
        user.payments
          ? Object.keys(user.payments).map((x) => {
              payment_pending.indexOf(x) == -1 ? payment_pending.push(x) : null;
            })
          : "";

        const paid = user.paid
          ? Object.keys(user.paid).map((x) => {
              var object = {};
              object[x] = moment.unix(user.paid[x].seconds).toDate();
              return object;
            })
          : [];
        var data = {
          memberID: memberId,
          PardnaId: user.id ? user.id : "",
          paid: user.paid ? user.paid : {},
          payInDueDate: moment().toDate(),
          payOutDate: moment().toDate(),
          pardnaName: user.name,
          uid: user.uid,
          payment_pending: payment_pending,
          people: user.people ? user.people : 0,
        };
        // var paidObject = user.paid ? Object.keys(user.paid) : [];
        // console.log(user.people);
        // console.log(data);
        // return;
        this.apiServiceService.putRequest("updateUser", data).subscribe(
          (response: any) => {
            this.alertnotification.successAlert(response.message);
            this.getPardnaDetail(this._id);
          },
          (error) => {
            console.log(error);
            this.alertnotification.errorAlert(error.message);
          }
        );
      } else {
        return;
      }
    });
  }

  async userInfo(detail) {
    var initialState = { userDetail: detail };
    this.modalRef = await this.modalService.show(UserDetailComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
  }
  openModal(userData) {
    var initialState = {
      pardnaId: this._id,
      pardnaName: this.pardnaDetails.name,
      userData: userData,
    };
    this.modalRef = this.modalService.show(PaymentModalComponent, {
      initialState,
    });
  }
}
