<div class="viewModal">
    <button type="button" class="close" (click)="close()">
      <span>&times;</span>
    </button>
  <div class="mb-2">
   <h3>How many people are in your group?</h3>
   <p>{{ modalData.application['How many people are in your group?'] }}</p>
  </div>
  <div class="mb-2">
    <h3>Are you currently running a pardna on another platform?</h3>
    <p>{{ modalData.application['Are you currently running a pardna on other platforms?'] }}</p>
   </div>
   <div class="mb-2">
    <h3>How are you running a pardna? (e.g WhatsApp)</h3>
    <p>{{ modalData.application['How are you running your Pardna? (e.g WhatsApp)'] }}</p>
   </div>
   <div>
    <h3>Any other comments?</h3>
    <p class="mb-0">{{ modalData.application['Any other comments?'] }}</p>
   </div>
</div>
